<template>
  <div :class="{ 'isRendered': isRendered }">
    <b-overlay :show="isLoading">
      <Matrix
        :matrix-data="matrixData"
        :from="matrixData.from"
        :to="matrixData.to"
        :day-count="matrixData.dayCount"
        :rows="filteredRows"
        :enable-click="true"
        :default-rows-per-page="defaultRowsPerPage"
        :full-height="fullHeight"
        @cell-click="onCellClick"
      />
    </b-overlay>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BOverlay,
} from 'bootstrap-vue'
import PpdmService from '@/service/ppdm.service'
import Matrix from '@/views/asup/backup-software/components/Matrix.vue'
import moment from '@/libs/moment'

export default {
  components: {
    BOverlay,
    Matrix,
  },
  props: {
    asupId: {
      type: String,
      required: true,
    },
    onlyErrors: {
      type: Boolean,
      default: false,
    },
    search: {
      type: String,
      default: () => null,
    },
    defaultRowsPerPage: {
      type: Number,
      default: 50,
    },
    timeRange: {
      type: Object,
      default: () => ({
        range: 'last7Days',
      }),
    },
    type: {
      type: String,
      default: 'primary',
      validator(value) {
        // The value must match one of these strings
        return ['primary', 'secondary'].includes(value)
      },
    },
    token: {
      type: String,
      default: () => null,
    },
    fullHeight: {
      type: Boolean,
      default: true,
    },
    expandRows: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      matrixData: {},
      isRendered: false,
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters({
      isCurrentUserHost: 'auth/isHost',
    }),
    filteredRows() {
      if (this.search) {
        return this.matrixData.rows
          .filter(x => x.displayName.toLowerCase().includes(this.search.toLowerCase()))
      }

      return this.matrixData.rows
    },
  },
  beforeMount() {
    this.$watch('timeRange', this.loadData)
    this.$watch('onlyErrors', this.loadData)
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.isLoading = true

      const params = {
        from: moment(this.timeRange.startDate).format('YYYY-MM-DD'),
        to: moment(this.timeRange.endDate).format('YYYY-MM-DD'),
        onlyErrors: this.onlyErrors,
      }

      const reqConfig = {
        disableTenantFilter: true,
      }

      if (this.token) {
        reqConfig.headers = {
          Authorization: `Bearer ${this.token}`,
        }
      }

      let getMatrix = null
      if (this.type === 'primary') {
        getMatrix = PpdmService.getClientPrimaryMatrixAsync
      } else if (this.type === 'secondary') {
        getMatrix = PpdmService.getClientSecondaryMatrixAsync
      }

      getMatrix(this.asupId, params, reqConfig)
        .then(result => {
          /* Normalize data  */
          result.rows.forEach(row => {
            if (this.expandRows === true) {
            // eslint-disable-next-line no-param-reassign
              row.showSubrows = true
            }

            if (!row.properties.notExisting) {
              // eslint-disable-next-line no-param-reassign
              row.routeTo = {
                name: 'ppdm-detail.client-detail',
                params: { clientName: row.displayName },
                query: {
                  from: params.from,
                  to: params.to,
                  onlyErrors: params.onlyErrors,
                },
              }
            } else {
              row.cols.forEach(col => {
                // eslint-disable-next-line no-param-reassign
                col.disableClick = true
              })
            }

            row.subRows.forEach(subRow => {
              // eslint-disable-next-line no-param-reassign
              subRow.routeTo = {
                name: 'ppdm-detail.protection-policy-detail',
                params: { name: subRow.properties.jobName },
                query: {
                  from: params.from,
                  to: params.to,
                  onlyErrors: params.onlyErrors,
                },
              }
            })
          })

          this.matrixData = result
        }).finally(() => {
          this.isLoading = false
          this.isRendered = true // isRendered should be set after isLoading is set, otherwise we would see a loading indicator in server-side rendered image
        })
    },
    onCellClick(e) {
      // eslint-disable-next-line no-unused-vars
      const { row, colIndex, day } = e

      if (row.properties.jobName) {
        // WF Row -> load WF data
        const { jobName } = row.properties
        const route = {
          name: 'ppdm-detail.protection-policy-detail',
          params: { name: jobName },
          query: {
            from: day.startOf('day').toISOString(),
            to: day.endOf('day').toISOString(),
          },
        }

        this.$router.push(route)
      } else {
        // Client Row -> load client data
        const clientName = row.displayName

        const route = {
          name: 'ppdm-detail.client-detail',
          params: { clientName },
          query: {
            from: day.startOf('day').toISOString(),
            to: day.endOf('day').toISOString(),
          },
        }

        this.$router.push(route)
      }
    },
  },
}
</script>

<style scoped>
  .form-inline {
    place-items: flex-start
  }

  .form-group {
    margin-right: 15px;
  }
</style>
